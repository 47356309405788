import '../../index.css';
import Logo from "../../images/YavrioLogo";
import React from "react";
import {useAuth} from "../../context/authContext";
import {Navbar} from "./Navbar";

const Header = () => {

    const { user } = useAuth();

    return (
        <div className="flex-col border-b bg-white border-slate-200 shadow-sm">
            <div className="flex flex-row justify-between items-center px-6 py-5">
                <div className='flex flex-row items-center gap-8'>
                    <Logo width='9rem'/>
                    <div style={{width: '0.1rem'}} className='h-7 opacity-20 bg-yavrio-blue'></div>
                    <h3 className='m-0'>Payment Authorisation Portal<span className='text-xs ml-2'>BETA</span></h3>
                </div>
                <div>
                    <span className="text-yavrio-blue text-sm">
                        {user.email}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Header;