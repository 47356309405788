import axios from "axios";

const SECURITY_APP_BACKEND_URL = process.env.REACT_APP_SECURITY_APP_BACKEND_URL;
const authToken = process.env.REACT_APP_ADMIN_TOKEN;

export const apiCall = async (method, body, path, token = null) => {

    if (!token) {
        token = authToken;
    }

    const headers = token ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    } : null;

    const config = {
        method: method,
        url: `${SECURITY_APP_BACKEND_URL}/${path}`,
        headers: {
            ...headers,
        },
        data: body,
    };

    try {
        const response = await axios(config);
        return response;
    } catch (error) {
        return error.response;
    }

}
