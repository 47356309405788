import { useEffect, useState } from "react"
import { Button } from "../ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
    DialogClose,
} from "../ui/dialog"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select"

export default function UpdateUserDialog(props) {

    const [role, setRole] = useState(props.user.role);
    const [maxAuthValue, setMaxAuthValue] = useState(props.user.maxAuthValue);
    let close = false;

    useEffect(() => {
        setRole(props.user.role);
        setMaxAuthValue(props.user.maxAuthValue);
    }, [props.user]);

    const handleSubmit = async () => {
        props.handleConfirmUpdate(role, maxAuthValue)
    }

    return (
      <Dialog closed={close}>
        <DialogTrigger asChild>
            {props.children}
        </DialogTrigger>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Edit User</DialogTitle>
            <DialogDescription>
              Update {props.user.name}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="name" className="text-right col-span-1">
                Role
              </Label>
              <div className="col-span-2">
                <Select
                        value={role} onValueChange={value => setRole(value)}
                    >
                        <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select Role"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="User">User</SelectItem>
                                <SelectItem value="Admin">Admin</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-4">
              <Label htmlFor="username" className="text-right col-span-1">
                Max Auth Value
              </Label>
              <Input
                id="username"
                value={maxAuthValue}
                defaultValue={maxAuthValue}
                onChange={(e) => setMaxAuthValue(e.target.value)}
                className="col-span-2"
                type="number"
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
                <div className="flex flex-row gap-2">
                    <Button variant="outline">Cancel</Button>
                    <Button type="submit" className="bg-yavrio-green" onClick={handleSubmit}>Update User</Button>
                </div>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }