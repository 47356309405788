import { msalInstance, loginRequest } from "../config/msal.config";
import { authenticateLogin } from './authentication.service';
import { getOrganisations } from './organisation.service';

export const microsoftLogin = async (setAuthenticated, setUser, user, toast) => {
    try {
        const accounts = msalInstance.getAllAccounts();
        let loginResponse;

        if (accounts.length > 0) {
            try {
                loginResponse = await msalInstance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0]
                });
            } catch (error) {
                loginResponse = await msalInstance.acquireTokenPopup(loginRequest);
            }
        } else {
            loginResponse = await msalInstance.loginPopup(loginRequest);
        }
        const authResponse = await authenticateLogin(loginResponse.accessToken);
        if (authResponse.status === 200) {
            setUser({ ...user, ...authResponse.data.data });
            const orgResponse = await getOrganisations(authResponse.data.data.token);
            if (orgResponse.status === 200) {
                const selectedOrganisation = orgResponse.data.data[0];
                setUser({ ...user, ...authResponse.data.data, organisationId: selectedOrganisation.id, organisationName: selectedOrganisation.name, organisations: orgResponse.data.data });
                setAuthenticated(true);
            }
        } else {
            throw new Error('Authentication Error: Unable to authenticate user');
        }
    } catch (error) {
        toast({
            title: "Error",
            description: "Error logging in: " + error,
            variant: "destructive",
        });
    }
};