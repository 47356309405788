import React from 'react';
import '../index.css'; // Import a separate CSS file for styling

const AuthSuccessPage = () => {
  return (
      <>
        <div className="App">

          <div className='page-wrapper min-h-screen !items-center !justify-center'>
            <h2>Authentication successful</h2>
          </div>

        </div>
      </>
  );
};

export default AuthSuccessPage;