import * as React from 'react';
import { Card, CardContent, CardHeader } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select"
import { useState } from "react";
import ConfirmDialog from "../ConfirmDialog";
import { useAuth } from '../../context/authContext';
// import { createUser } from '../services/user.service';

export default function CreateUserForm({createUser}) {

    const [user, setUser] = useState({
        name: '',
        email: '',
        role: '',
        maxAuthValue: 0,
    });

    const [isChecked, setIsChecked] = useState(false);
    const { user: authUser } = useAuth();

    return (
        <>
            <Card>
                <CardHeader>
                    <h3>Create New User</h3>
                </CardHeader>
                <CardContent>
                    <div className='grid grid-cols-1 gap-4'>
                        <Input
                            required
                            type='text'
                            id="outlined-required"
                            label=""
                            placeholder="Name"
                            onChange={(e) => {
                                setUser({...user, name: e.target.value})
                            }}
                        />
                        <Input
                            required
                            id="email"
                            label=""
                            placeholder="Email"
                            onChange={(e) => {
                                setUser({...user, email: e.target.value})
                            }}
                        />
                        <Select
                            value={user.role} onValueChange={value => setUser({...user, role: value})}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select Role"/>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="User">User</SelectItem>
                                    <SelectItem value="Admin">Admin</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <Input
                            required
                            type='number'
                            id="maxAuthValue"
                            label=""
                            placeholder="Max Auth Value"
                            onChange={(e) => {
                                setUser({...user, maxAuthValue: parseInt(e.target.value)})
                            }}
                        />
                    </div>
                    <div className="items-top flex space-x-2 my-8">
                        <Checkbox id="terms" onCheckedChange={() => setIsChecked(!isChecked)}/>
                        <div className="grid gap-1.5 leading-none">
                            <label
                                htmlFor="terms"
                                className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                Accept
                            </label>
                            <p className="text-sm">
                                By submitting this information, you have granted permission for a new individual to authorize payments on behalf of your organization. You also confirm that you are authorized to provide this permission.
                            </p>
                        </div>
                    </div>
                    <ConfirmDialog
                        title="Confirm Create User"
                        description={`Are you sure you want to add ${user.name} with role ${user.role}?`}
                        cancel='Cancel'
                        action='Create'
                        handleAction={() => createUser({
                            name: user.name,
                            email: user.email,
                            role: user.role,
                            maxAuthValue: user.maxAuthValue,
                        })}
                    >
                        <Button className="yvr-button"
                                disabled={!isChecked || user.name === '' || user.email === '' || user.role === '' || user.maxAuthValue === 0}
                        >
                            Create User
                        </Button>
                    </ConfirmDialog>
                </CardContent>
            </Card>
        </>
    );
}