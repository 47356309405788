import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState({
        id: null,
        userUUID: '',
        token: '',
        organisationId: null,
        organisationName: '',
        name: '',
        email: '',
        picture: '',
        role: '',
        isActive: false,
        twoFactorEnabled: false,
        organisations: [],
    });

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                setAuthenticated,
                user,
                setUser,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};