import React from 'react';
import '../index.css';

const NotFoundPage = () => {

    return (
        <div className="flex flex-col justify-center items-center h-screen w-full">
            <h2>404 Page not found</h2>
        </div>
    );
};

export default NotFoundPage;