import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage", // "sessionStorage" or"localStorage"
        storeAuthStateInCookie: true,
    },
};

export const loginRequest = {
    scopes: ['User.Read', 'openid', 'profile', 'email', 'offline_access'],
};
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
export { msalInstance };
