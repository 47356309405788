import React, { useState, useEffect } from 'react';
import '../index.css';
import Header from "../components/layout/Header";
import PaymentDetails from "../components/payments/PaymentDetails";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from 'react-feather';
import Footer from "../components/layout/Footer";
import PaymentAuth from "../components/payments/PaymentAuth";
import { getPaymentDetails } from '../services/payment.service';
import { useAuth } from '../context/authContext';
import PaymentSummary from "../components/payments/PaymentSummary";
import PaymentAuthLogs from "../components/payments/PaymentAuthLogs";

const PaymentAuthPage = () => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [updatePage, setUpdatePage] = useState(false);
    const { user } = useAuth();

    const { paymentId } = useParams();

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            const response = await getPaymentDetails(user.token, paymentId);
            if (response && response.status === 200) {
                setPaymentDetails(response.data.data);
            }
        };

        fetchPaymentDetails();
    }, [paymentId, user.token, updatePage]);

    return (
        <>
            <div className="App">
                <div className=''>
                    <Link className='underline flex flex-row mr-auto' to={'/payments'}>
                        <ArrowLeft className='mr-1 w-8' />
                        <p>All payments</p>
                    </Link>
                    {paymentDetails && (
                        <div className='flex flex-row gap-6 mt-4 mb-6 w-full'>
                            <PaymentAuth
                                paymentDetails={paymentDetails}
                                updatePage={updatePage}
                                setUpdatePage={setUpdatePage}
                            />
                            <PaymentSummary
                                paymentDetails={paymentDetails}
                            />
                        </div>
                    )}
                    <div className='flex flex-col w-full gap-6'>
                        <PaymentAuthLogs
                            updatePage={updatePage}
                        />
                        <PaymentDetails />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentAuthPage;