import {Card, CardContent, CardHeader} from "../../components/ui/card";
import QRCode from "react-qr-code";
import OtpInput from "react-otp-input";
import {Button} from "../../components/ui/button";
import React, {useState, useEffect} from "react";
import {useAuth} from "../../context/authContext";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../services/user.service";
import { getAuthenticatorInfo, verifyAuthenticatorOtp, recoverAuthenticator } from "../../services/authentication.service";

const QRCodeSetup = () => {
    const [otp, setOtp] = useState('');
    const [view, setView] = useState('setup'); // 'initial', 'setup', 'recover', 'recoveryCodes'
    const [recoveryCode, setRecoveryCode] = useState('');
    const { user, setUser } = useAuth();
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
    const [otpAuthUrl, setOtpAuthUrl] = useState('');
    const [recoveryCodes, setRecoveryCodes] = useState([]);

    const handleVerifyCode = async () => {
        const response = await verifyAuthenticatorOtp(user.token, otp);
        if (response && response.status === 200) {
            setRecoveryCodes(response.data.data);
            setOtp('');
            setView('recoveryCodes');
        }
    };

    const handleEnterRecoveryCode = async () => {
        const recoveryCodeInt = parseInt(recoveryCode, 10);
        const response = await recoverAuthenticator(user.token, recoveryCodeInt);
        if (response && response.status === 200) {
            const { otpAuthUrl } = response.data.data;
            setOtpAuthUrl(otpAuthUrl);
            setRecoveryCode('');
            setView('setup');
        }
    };

    useEffect(() => {
        if (view === 'setup') {
            const fetchUserDetails = async () => {
                const response = await getUserDetails(user.token, user.organisationId, user.id);
                if (response && response.status === 200) {
                    const { twoFactorEnabled } = response.data.data;
                    setUser({ ...user, twoFactorEnabled });
                    setIsTwoFactorEnabled(!twoFactorEnabled);
                }
            };

            const fetchAuthenticatorInfo = async () => {
                const response = await getAuthenticatorInfo(user.token);
                if (response && response.status === 200) {
                    const { otpAuthUrl } = response.data.data;
                    setOtpAuthUrl(otpAuthUrl);
                }
            };
        
            fetchUserDetails();
            fetchAuthenticatorInfo();
        }
    }, [view]);

    return (
        <Card className='max-w-xl min-w-128'>
            <CardHeader>
                <h3>Authenticator App Setup</h3>
            </CardHeader>
            <CardContent>
                {view === 'initial' && (
                    <div className="flex flex-col items-center">
                        <Button className="yvr-button mt-4" onClick={() => setView('setup')}>Set Up Authenticator</Button>
                    </div>
                )}
                {view === 'setup' && (
                    <>
                        {isTwoFactorEnabled ? (
                            <div className="flex flex-col justify-center items-center mb-10 px-12">
                                <p>Scan the QR to setup two-factor authentication</p>
                                <QRCode
                                    value={otpAuthUrl}
                                    size={256}
                                    className="mt-6 mb-12"
                                />
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props}
                                                                   className="border-2 border-gray-300 text-center !w-10 !h-10 mx-2"
                                    />}
                                />
                                <Button className="yvr-button mt-12" onClick={handleVerifyCode}>Verify Code</Button>
                            </div>
                        ) : (
                            <>
                                <p className='font-bold'>Two-factor authentication already setup.</p>
                                <p style={{ }}>
                                    Use recovery codes if you have lost access to your authenticator app.
                                </p>
                                <Button className="yvr-button mt-8" onClick={() => setView('recover')}>Recover Authenticator</Button>
                            </>
                        )}
                    </>
                )}
                {view === 'recover' && (
                    <div className="flex flex-col justify-center items-center mb-10 px-12">
                        <p>Enter your 8-digit recovery code</p>
                        <OtpInput
                            value={recoveryCode}
                            onChange={setRecoveryCode}
                            numInputs={8}
                            renderInput={(props) => <input {...props}
                                                           className="border-2 border-gray-300 text-center !w-10 !h-10 mx-2"
                            />}
                        />
                        <div className="flex flex-row justify-center mt-8 gap-3">
                            <Button className="yvr-button-secondary" onClick={() => setView('setup')}>Back</Button>
                            <Button className="yvr-button" onClick={handleEnterRecoveryCode}>Submit Code</Button>
                        </div>
                    </div>
                )}
                {view === 'recoveryCodes' && (
                    <div className="flex flex-col items-start">
                        <p>Your recovery codes:</p>
                        {/* Display recovery codes here */}
                        <ul>
                            {recoveryCodes.map((code, index) => (
                                <li key={index}>{code.code} - {code.used ? 'Used' : 'Unused'}</li>
                            ))}
                        </ul>
                        <p className='mt-8'>Secure your account access: Save your 2FA recovery codes in a safe place. Essential for regaining access if you need to reset your authenticator app.</p>
                        <Button className="yvr-button mt-8" onClick={() => setView('setup')}>Continue</Button>
                    </div>
                )}
                {/*{view !== 'initial' && (*/}
                {/*    <Link to="/settings" className="back-arrow bottom-left" onClick={() => {*/}
                {/*        setView('initial');*/}
                {/*        setOtp(''); // Clear otp field*/}
                {/*        setRecoveryCode(''); // Clear recoveryCode field*/}
                {/*    }}>Back</Link>*/}
                {/*)}*/}
            </CardContent>
        </Card>
    );
}

export default QRCodeSetup;