import React from 'react';
import '../../index.css';
import { useAuth } from "../../context/authContext";
import { Card, CardContent, CardHeader } from "../ui/card";
import PaymentStatusJourney from './PaymentStatusJourney';

const currencyFormat = (value) => {
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const convertAccountDetails = (type) => {
    switch (type) {
        case 'IBAN':
            return 'IBAN';
        case 'BIC':
            return 'BIC';
        case 'ACCOUNT_NO':
            return 'Account Number';
        case 'ACCOUNT_NUMBER':
                return 'Account Number';
        case 'SORT_CODE':
            return 'Sort Code';
        default:
            return '';
    }
};

const PaymentSummary = ({paymentDetails}) => {
    const { authenticated } = useAuth();
    const payment = paymentDetails;
    let bulk = false;
    if (payment && payment.paymentData.payments) {
        bulk = true;
    }

    let statusNumber;
    if (paymentDetails.obtainedAuthorisations === 0) {
        statusNumber = 0;
    } else if (paymentDetails.obtainedAuthorisations === paymentDetails.requiredAuthorisations) {
        statusNumber = 2;
    } else {
        statusNumber = 1;
    }

    const isRejected = payment.status === 'Rejected';

    return (
        <div className='w-full yvr-payment-summary'>
            <Card>
                <CardHeader>
                    <h3>Payment Summary</h3>
                </CardHeader>
                <CardContent>
                    { authenticated && payment ?
                        <>
                            <div className='grid grid-cols-2 gap-10'>

                                <div>
                                    <p>Status: <span className="font-semibold text-gray-600">{payment.status}</span></p>
                                    <p>Type: <span className="font-semibold text-gray-600">{payment.type}</span></p>
                                    <p>Method: <span className="font-semibold text-gray-600">BACS</span></p>
                                </div>

                                <div>
                                    <p><span className="font-semibold text-gray-600">{payment.payer.name}</span>
                                    </p>
                                    { payment.payer.accountIdentifications.map((account, index) => (
                                        <p key={index}>{convertAccountDetails(account.type)}: <span className="font-semibold text-gray-600">{account.identification}</span></p>
                                    ))}
                                </div>

                                <div>
                                    <p>Total Value: <span
                                        className="font-semibold text-gray-600">{currencyFormat(payment.totalAmount)}</span></p>
                                    { bulk ? 
                                        <p>Currency: <span className="font-semibold text-gray-600">{payment.paymentData.payments[0].amount.currency}</span></p>
                                        :
                                        <p>Currency: <span className="font-semibold text-gray-600">{payment.paymentData.amount.currency}</span></p>    
                                    }
                                    {/* <p>Currency: <span className="font-bold text-gray-800">{payment.paymentData.amount.currency}</span></p> */}
                                </div>

                            </div>

                            <PaymentStatusJourney status={statusNumber} isRejected={isRejected} obtainedAuthorisations={paymentDetails.obtainedAuthorisations} />
                        </>
                        :
                        <div className="blur select-none">
                        <p>Details cannot be displayed.</p>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentSummary;