import { apiCall } from './api.service';

export const authenticateLogin = async (token) => {
    const method = 'post';
    const body = { token };
    const path = 'api/v1/authenticate/login';

    const response = await apiCall(method, body, path);
    return response;
}

export const getAuthenticatorInfo = async (token) => {
    const method = 'get';
    const path = 'api/v1/authenticate';

    const response = await apiCall(method, {}, path, token);
    return response;
}

export const verifyAuthenticatorOtp = async (token, otp) => {
    const method = 'post';
    const body = { otp };
    const path = 'api/v1/authenticate/verify';

    const response = await apiCall(method, body, path, token);
    return response;
}

export const recoverAuthenticator = async (token, recoveryCode) => {
    const method = 'post';
    const body = { recoveryCode };
    const path = 'api/v1/authenticate/recover';

    const response = await apiCall(method, body, path, token);
    return response;
}
