import React, {useEffect, useState} from 'react';
import { Input } from '../ui/input';
import { Button } from "../ui/button";
import {Card, CardContent, CardHeader} from "../ui/card";
import ConfirmDialog from "../ConfirmDialog";
import { updateOrganisation } from '../../services/organisation.service';
import { useAuth } from '../../context/authContext';
import { useToast } from '../../components/ui/use-toast';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '../ui/tooltip';
import { Switch } from '../ui/switch';

export default function UpdateAuthCount({users, authCount, setAuthCount, restrictSubmitterAuth, setRestrictSubmitterAuth}) {

    const [newAuthCount, setNewAuthCount] = useState(authCount);
    const [newRestrictSubmitterAuth, setNewRestrictSubmitterAuth] = useState(restrictSubmitterAuth);
    const { toast } = useToast()
    const { user: authUser } = useAuth();

    useEffect(() => {
        setNewAuthCount(authCount);
        setNewRestrictSubmitterAuth(restrictSubmitterAuth);
    }, [authCount, restrictSubmitterAuth]);

    const handleConfirmUpdate = async () => {
        const organisationId = authUser.organisationId;
        const requestBody = {
            requiredAuthorisations: newAuthCount,
            restrictSubmitterAuth: newRestrictSubmitterAuth,
        };

        const response = await updateOrganisation(organisationId, requestBody, authUser.token);
        if (response && response.status === 200) {
            setAuthCount(newAuthCount);
            setRestrictSubmitterAuth(newRestrictSubmitterAuth);

            let toastMessage = "Updated: ";
            if (newAuthCount !== authCount) {
                toastMessage += "Authorisation Count";
            }
            if (newRestrictSubmitterAuth !== restrictSubmitterAuth) {
                toastMessage += (newAuthCount !== authCount ? " and " : "") + "Restrict Submitter settings";
            }

            toast({
                title: "Success",
                description: toastMessage,
                variant: "success",
            });
        } else {
            toast({
                title: "Error",
                description: "Error updating settings",
                variant: "destructive",
            });
        }
    };
    const isUpdateDisabled = newAuthCount === authCount && newRestrictSubmitterAuth === restrictSubmitterAuth;

    let confirmDialogMessage = "Are you sure you want to ";
    if (newAuthCount !== authCount) {
        confirmDialogMessage += `update the authorisation count to ${newAuthCount}`;

    }
    if (newRestrictSubmitterAuth !== restrictSubmitterAuth) {
        confirmDialogMessage += (newAuthCount !== authCount ? " and " : "") + 
            `${newRestrictSubmitterAuth ? "enable" : "disable"} the Restrict Submitter settings`;
    }
    confirmDialogMessage += "?";

    return (
        <>
            <Card>
                <CardHeader>
                    <h3>Authorisation Settings</h3>
                </CardHeader>
                <CardContent>
                    <p>Payments will only be sent once { `${authCount} user${authCount === 1 ? ' has' : 's have'}` } successfully authorised.</p>
                    <Input
                        required
                        type='number'
                        className={'max-w-xs mt-6'}
                        value={newAuthCount}
                        id="outlined-required"
                        label=""
                        placeholder="1"
                        onChange={(e) => {
                            if (e.target.value < 1) {
                                setNewAuthCount(1);
                            } else if (e.target.value > users.length) {
                                setNewAuthCount(users.length);
                            } else {
                                setNewAuthCount(Number(e.target.value));
                            }
                        }}
                    />
                    <div className="mt-8 mb-6">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <label className="flex items-center text-sm text-gray-600">
                                        <Switch
                                            checked={newRestrictSubmitterAuth}
                                            onCheckedChange={() => setNewRestrictSubmitterAuth(!newRestrictSubmitterAuth)}
                                            className="mr-2"
                                        />
                                        Submitter cannot authorise
                                    </label>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p className="text-xs max-w-xs">If enabled, the person who submitted the payment request cannot authorise it.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                    <ConfirmDialog
                        title="Update Authorisation Count"
                        description={confirmDialogMessage}
                        handleAction={handleConfirmUpdate}
                        cancel='Cancel'
                        action='Update'
                    >
                        <Button
                            disabled={isUpdateDisabled}
                            className={'mt-4 yvr-button'}
                        >
                            Update
                        </Button>
                    </ConfirmDialog>
                </CardContent>
            </Card>
        </>
    );
}