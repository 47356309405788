import React, { useState } from 'react';
import '../../index.css';
import { useAuth } from "../../context/authContext";
import { useToast } from "../../components/ui/use-toast";
import OrganisationList from './OrganisationList';
import microsoft from '../../images/microsoft-icon.svg';
import { microsoftLogin } from '../../services/auth.service';

const OAuthLoginButtons = () => {
    const { toast } = useToast();
    const { setAuthenticated, setUser, user } = useAuth();

    const handleMicrosoftLogin = () => {
        microsoftLogin(setAuthenticated, setUser, user, toast);
    };

    return (
        <>
            <div className="oauth-buttons text-center grid grid-cols-1 gap-2">
                <button
                    onClick={handleMicrosoftLogin}
                    className="bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-none font-medium text-gray-700 px-6 py-4 flex flex-row">
                    <img src={microsoft} alt="" className="mr-3"/>
                    <span className="w-48 text-center">Sign in with Microsoft</span>
                </button>
            </div>
        </>
    );
};

export default OAuthLoginButtons;