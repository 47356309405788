"use client";

import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "../../utils";

const CountProgress = React.forwardRef(
  ({ className, value, total, ...props }, ref) => (
    <div className="flex flex-col items-center">
      <span>{`${value}/${total}`}</span>
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
          className
        )}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className="h-full w-full flex-1 bg-primary transition-all"
          style={{ transform: `translateX(-${((total - value) / total) * 100}%)` }}
        />
      </ProgressPrimitive.Root>
    </div>
  )
);
CountProgress.displayName = ProgressPrimitive.Root.displayName;

export { CountProgress };
