import {Card, CardContent, CardHeader} from "../../components/ui/card";
import React from "react";
import {useAuth} from "../../context/authContext";

const SettingsCard = () => {

    const { user } = useAuth();

    return (
        <Card className='min-w-72'>
            <CardHeader>
                <h3>Profile</h3>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col">
                    <p><b>Organisation:</b> {user.organisationName}</p>
                    <p><b>Name:</b> {user.name}</p>
                    <p><b>Email:</b> {user.email}</p>
                    <p><b>Role:</b> {user.role}</p>
                </div>
            </CardContent>
        </Card>
    );
}

export default SettingsCard;