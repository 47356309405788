import {
    Table,
    TableBody,
    TableCell, TableHead, TableHeader,
    TableRow,
} from "../ui/table"

import {Card, CardContent, CardHeader} from "../ui/card"
import {Button} from "../ui/button";

import { Trash2, Pencil } from "lucide-react";
import {useState} from "react";
import ConfirmDialog from "../ConfirmDialog";
import UpdateUserDialog from "./UpdateUserDialog";

export default function UsersTable({ users, setUsers, onDeleteUser, onUpdateUser }) {

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedUser, setSelectedUser] = useState({
        id: '',
        name: '',
        email: '',
        role: '',
        maxAuthValue: 0,
    });

    const handleSelectRow = (id) => {
        setSelectedRow(id);
        setSelectedUser({
            id: id,
            name: users.find(user => user.id === id).name,
            email: users.find(user => user.id === id).email,
            role: users.find(user => user.id === id).role,
            maxAuthValue: users.find(user => user.id === id).maxAuthValue,
        });
    };
    
    const handleConfirmDelete = async () => {
        const userDeleted = await onDeleteUser(selectedRow);

        if (userDeleted) {
            const updatedData = users.filter(user => user.id !== selectedRow);
            setUsers(updatedData);
        }
    };

    const handleConfirmUpdate = async (role, maxAuthValue) => {
        const updatedUser = {
            ...selectedUser,
            role: role,
            maxAuthValue: maxAuthValue,
        };

        await onUpdateUser(updatedUser);
    };

    // Sort users by Id before rendering the table
    const sortedUsers = users.sort((a, b) => a.id - b.id);

    return (
        <>
            <Card>
                <CardHeader>
                    <h3>Users</h3>
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHeader>
                            <TableRow className='hover:bg-white'>
                                <TableHead className="w-[100px]">Name</TableHead>
                                <TableHead>Email</TableHead>
                                <TableHead>Role</TableHead>
                                <TableHead>Max Auth Value</TableHead>
                                <TableHead></TableHead>
                                <TableHead></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {sortedUsers.map((user) => (
                                <TableRow key={user.userUUID} className='hover:bg-white'>
                                    <TableCell className="w-[200px]">{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{user.maxAuthValue.toLocaleString()}</TableCell>
                                    <TableCell className='text-right'>
                                        <UpdateUserDialog
                                            handleConfirmUpdate={handleConfirmUpdate}
                                            user={selectedUser}
                                        >
                                            <Button variant={'ghost'} className='p-2 text-yavrio-blue' onClick={() => handleSelectRow(user.id)}><Pencil className="h-4 w-4" /></Button>
                                        </UpdateUserDialog>
                                    </TableCell>
                                    <TableCell className='text-right'>
                                        <ConfirmDialog
                                            title="Confirm Delete"
                                            description={
                                                <>
                                                    {`If you proceed, `}
                                                    <b>{`${selectedUser.name} (${selectedUser.email})`}</b>
                                                    {` will no longer be able to approve payments. Are you sure you want to delete this user?`}
                                                </>
                                            }
                                            cancel='Cancel'
                                            action='Delete'
                                            handleAction={handleConfirmDelete}
                                        >
                                            <Button variant={'ghost'} className='p-2 text-red-400' onClick={() => handleSelectRow(user.id)}><Trash2 className="h-4 w-4" /></Button>
                                        </ConfirmDialog>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </>
    )
}
