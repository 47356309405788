import { Check, X } from 'lucide-react';

const PaymentStatusJourney = ({ status, isRejected, obtainedAuthorisations }) => {

  const getCircleColor = (index) => {
    if (isRejected && index === 2) return '#b91c1c';
    return status >= index ? '#069B62' : 'gray';
  };

  const getLineColor = (index) => {
    if (isRejected) {
      if (index === 0 && obtainedAuthorisations > 0) return '#069B62';
      if (index === 1) return '#b91c1c';
      if (index === 0 && obtainedAuthorisations === 0) return '#b91c1c';
    }
    return status > index ? '#069B62' : 'gray';
  };

  const renderCircle = (index, isRejectedCircle = false) => (
    <div
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: getCircleColor(index),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: status >= index || isRejectedCircle ? '100%' : '40%',
      }}
    >
      {isRejectedCircle ? <X className='text-white' /> : (status >= index ? <Check className='text-white' /> : null)}
    </div>
  );

  const renderLine = (index) => (
    <div
      style={{
        height: '2px',
        flex: '1',
        backgroundColor: getLineColor(index),
        opacity: status > index || (isRejected && index >= 0) ? '100%' : '40%',
      }}
    />
  );

  const renderJourney = () => {
    if (isRejected && obtainedAuthorisations === 0) {
      return (
        <>
          {renderLine(0)}
          {renderLine(1)}
          {renderCircle(2, true)}
        </>
      );
    } else {
      return (
        <>
          {renderLine(0)}
          <div className='flex flex-col items-center relative'>
            {renderCircle(1)}
          </div>
          {renderLine(1)}
          <div className='flex flex-col items-center relative'>
            {renderCircle(2, isRejected)}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className='mt-8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '500px', padding: '0 2.7rem' }}>
        <div className='flex flex-col items-center relative'>
          {renderCircle(0)}
        </div>
        {renderJourney()}
      </div>
      <div className='mt-2' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', maxWidth: '500px' }}>
        <p className={status >= 0 ? 'opacity-100 w-32 text-center text-xs font-semibold' : 'opacity-50 w-32 text-center text-xs font-semibold'}>AWAITING<br />AUTHORISATION</p>
        {isRejected && obtainedAuthorisations === 0 ? null : (
          <p className={status >= 1 ? 'opacity-100 w-32 text-center text-xs font-semibold' : 'opacity-50 w-32 text-center text-xs font-semibold'}>PARTIAL<br />AUTHORISATION</p>
        )}
        <p className={status >= 2 || isRejected ? 'opacity-100 w-32 text-center text-xs font-semibold' : 'opacity-50 w-32 text-center text-xs font-semibold'}>{isRejected ? 'REJECTED' : 'AUTHORISED'}</p>
      </div>
    </>
  );
};

export default PaymentStatusJourney;