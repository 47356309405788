import React, { useEffect, useState } from 'react';
import '../../index.css';
import { useAuth } from "../../context/authContext";
import { Card, CardContent, CardHeader } from "../ui/card";
import { useParams } from 'react-router-dom';
import { getPaymentDetails, getPaymentLogs } from '../../services/payment.service';

const PaymentSummary = ({updatePage}) => {
    const { authenticated } = useAuth();
    const { paymentId } = useParams();
    const { user } = useAuth();
    const [paymentLogs, setPaymentLogs] = useState([]);

    useEffect(() => {
        const fetchPaymentLogs = async () => {
            const logsResponse = await getPaymentLogs(user.token, paymentId);
            if (logsResponse && logsResponse.status === 200) {
                setPaymentLogs(logsResponse.data.data);
            }
        };
        fetchPaymentLogs();
    }, [updatePage]);

    const renderPaymentLogs = () => {
        return paymentLogs.map((log, index) => (
            <div key={index}>
                <p className="text-sm">{log.message}</p>
            </div>
        ));
    };

    return (
        <div className='min-w-max'>
            <Card>
                <CardHeader>
                    <h3>Authorisation Logs</h3>
                </CardHeader>
                <CardContent>
                    { authenticated && paymentLogs ?
                        <div>
                            {renderPaymentLogs()}
                        </div>
                        :
                        <div className="blur select-none">
                            <p>Details cannot be displayed.</p>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentSummary;