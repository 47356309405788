import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import PaymentsPage from './pages/PaymentsPage';
import NotFoundPage from "./pages/NotFoundPage";
import AuthSuccessPage from "./pages/AuthSuccessPage";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import { useAuth } from "./context/authContext";
import PaymentAuthPage from "./pages/PaymentAuthPage";
import UsersPage from "./pages/UsersPage";
import Layout from "./components/layout/Layout";

function App() {

    const { authenticated, user } = useAuth();

    const standardRoutes = [
        {
            path: '/',
            element: <Navigate to='/payments' />
        },
        {
            path: '/payments',
            element: <PaymentsPage />
        },
        {
            path: '/payments/:paymentId',
            element: <PaymentAuthPage />
        },
        {
            path: '/settings',
            element: <SettingsPage />
        },
        {
            path: '/success',
            element: <AuthSuccessPage />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ];

    const adminRoutes = [
        {
            path: '/users',
            element: <UsersPage />
        },
    ];

    if (!authenticated) {
        return (
            <LoginPage />
        );
    }

    return (
        <Routes>
            {/* Standard Routes */}
            {standardRoutes.map((route, index) => (
                <Route 
                    key={index} 
                    path={route.path}
                    element={
                        <Layout>
                            {route.element}
                        </Layout>
                    }
                />
            ))}

            {/* Admin Routes */}
            {user.role === 'Admin' && adminRoutes.map((route, index) => (
                <Route 
                    key={index} 
                    path={route.path}
                    element={
                        <Layout>
                            {route.element}
                        </Layout>
                    }
                />
            ))}
        </Routes>
    );
}

export default App;
