import { apiCall } from './api.service';

export const getOrganisation = async (organisationId, token) => {
    const method = 'get';
    const path = `api/v1/organisations/${organisationId}`;
    
    const response = await apiCall(method, {}, path, token);

    return response;
};

export const getOrganisations = async (token) => {
    const method = 'get';
    const path = `api/v1/organisations`;
    
    const response = await apiCall(method, {}, path, token);

    return response;
};

export const updateOrganisation = async (organisationId, requestBody, token) => {
    const method = 'put';
    const path = `api/v1/organisations/${organisationId}`;
    
    const response = await apiCall(method, requestBody, path, token);

    return response;
};