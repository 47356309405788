import React, { useEffect, useState } from 'react';
import '../../index.css';
import { useAuth } from "../../context/authContext";
import { Card, CardContent, CardHeader } from "../ui/card";
import {Link, useParams} from 'react-router-dom';
import { getPaymentDetails } from '../../services/payment.service';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/ui/table";
import {CountProgress} from "../../components/ui/count-progress";
import {Button} from "../../components/ui/button";

const currencyFormat = (value) => {
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const PaymentDetails = () => {
    const { authenticated } = useAuth();
    const { paymentId } = useParams();
    const { user } = useAuth();
    const [payment, setPayment] = useState(null);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            const response = await getPaymentDetails(user.token, paymentId);
            if (response && response.status === 200) {
                setPayment(response.data.data);
            }
        };

        fetchPaymentDetails();
    }, []);

    const renderPaymentData = (paymentData) => {
        if (paymentData.payments) { // Bulk payment
            return (
                <>
                    <Table>
                        <TableHeader>
                            <TableRow className='hover:bg-white'>
                                <TableHead>Payee</TableHead>
                                <TableHead>Reference</TableHead>
                                <TableHead className="text-right">Amount</TableHead>
                                <TableHead>Currency</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {paymentData.payments.map((payment) => (
                                <>
                                    <TableRow key={payment.paymentId}>
                                        <TableCell>{payment.payee}</TableCell>
                                        <TableCell>{payment.reference}</TableCell>
                                        <TableCell className="text-right">{currencyFormat(payment.amount.amount)}</TableCell>
                                        <TableCell>{payment.amount.currency}</TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </>
            );
        } else { // Single payment
            return (
                <>
                    <Table>
                        <TableHeader>
                            <TableRow className='hover:bg-white'>
                                <TableHead>Payee</TableHead>
                                <TableHead>Reference</TableHead>
                                <TableHead className="text-right">Amount</TableHead>
                                <TableHead>Currency</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow key={payment.paymentId}>
                                <TableCell>{payment.paymentData.payee}</TableCell>
                                <TableCell>{payment.paymentData.reference}</TableCell>
                                <TableCell className="text-right">{currencyFormat(paymentData.amount.amount)}</TableCell>
                                <TableCell>{payment.paymentData.amount.currency}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            );
        }
    };

    return (
        <div className='min-w-max'>
            <Card>
                <CardHeader>
                    <h3>Payment Details</h3>
                </CardHeader>
                <CardContent>
                    { authenticated && payment ?
                        <div>
                            {renderPaymentData(payment.paymentData)}
                        </div>
                        :
                        <div className="blur select-none">
                            <p>Details cannot be displayed.</p>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentDetails;