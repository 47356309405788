import React from 'react';
import '../index.css';
import { GoogleOAuthProvider } from "@react-oauth/google";
import OAuthLoginButtons from "../components/login/OAuthLoginButtons";
import Logo from "../images/YavrioLogo";
import { Link } from "react-router-dom";

const LoginPage = () => {

    return (
        <div className="App">
            <div className='flex flex-col w-screen h-screen justify-center items-center'>
                <div className="yvr-box items-center max-w-sm !p-10">
                    <Logo width='12rem' />
                    <h3 className='mt-8'>Welcome</h3>
                    <p className='text-center mt-2 mb-8'>Please sign in to access the payments authorisation portal.</p>
                    <GoogleOAuthProvider clientId="your-client-id">
                        <OAuthLoginButtons />
                    </GoogleOAuthProvider>
                    <Link to='mailto:support@yavr.io' className={'text-blue-500 text-sm mt-6'}>Need assistance?</Link>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;