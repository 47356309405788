import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
    return (
        <div className="flex flex-col h-screen w-screen overflow-hidden">
            <Header />
            <main className="flex flex-1 overflow-auto">
                <Sidebar />
                <div className='p-10 w-full h-auto overflow-auto'>
                    {children}
                </div>
            </main>
        </div>
    );
};

export default Layout;