import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../components/ui/alert-dialog"

export default function ConfirmDialog(props) {

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                {props.children}
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        {props.title}
                    </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogDescription>
                    {props.description}
                </AlertDialogDescription>
                <AlertDialogFooter>
                    <AlertDialogCancel>
                        {props.cancel}
                    </AlertDialogCancel>
                    <AlertDialogAction onClick={props.handleAction} className="bg-red-700">
                        {props.action}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}