import React from 'react';
import '../index.css';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import QRCodeSetup from "../components/settings/QRCodeSetup";
import SettingsCard from "../components/settings/SettingsCard";

const SettingsPage = () => {

    return (
        <>

            <div className="App">

                <div className=''>
                    
                    <div className='flex flex-row gap-6 justify-center items-start'>
                        <SettingsCard />
                        <QRCodeSetup />
                    </div>

                </div>

            </div>

        </>

    );
};

export default SettingsPage;