import { apiCall } from './api.service';

export const createUser = async (token, organisationId, userData) => {
    const method = 'post';
    const path = `api/v1/admin/organisations/${organisationId}/users`;

    const response = await apiCall(method, userData, path, null);
    return response;
}

export const getUsers = async (token, organisationId) => {
    const method = 'get';
    const path = `api/v1/admin/organisations/${organisationId}/users`;

    const response = await apiCall(method, {}, path, null);
    return response;
}

export const getUserDetails = async (token, organisationId, userId) => {
    const method = 'get';
    const path = `api/v1/admin/organisations/${organisationId}/users/${userId}`;

    const response = await apiCall(method, {}, path, null);
    return response;
};

export const deleteUser = async (token, organisationId, userId) => {
    const method = 'delete';
    const path = `api/v1/admin/organisations/${organisationId}/users/${userId}`;

    const response = await apiCall(method, {}, path, null);
    return response;
}

export const updateUser = async (token, organisationId, userId, userData) => {
    const method = 'put';
    const path = `api/v1/admin/organisations/${organisationId}/users/${userId}`;

    const response = await apiCall(method, userData, path, null);
    return response;
}