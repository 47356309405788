"use client"
import * as React from "react"
import { useState } from "react"

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import { Link } from "react-router-dom"
import { CountProgress } from "../../components/ui/count-progress"
import { Card, CardHeader, CardContent } from "../../components/ui/card"

import { Button } from "../../components/ui/button"

import { LoadingSpinner } from "../../components/ui/loading-spinner"
import { Input } from "../../components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../components/ui/table"
import { Check, X } from "lucide-react"

const currencyFormat = (value) => {
    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const columns = [
    { accessorKey: 'createdAt', header: 'Date', cell: info => new Date(info.row.original.createdAt).toLocaleDateString() },
    { accessorKey: 'type', header: 'Payment Type' },
    { accessorKey: 'payee', header: 'Payee', cell: info => (
        info.row.original.type === "Bulk" 
            ? '[multiple]' 
            : info.row.original.paymentData.payee
        ) 
    },
    { accessorKey: 'reference', header: 'Reference', cell: info => (
        info.row.original.type === "Bulk" 
            ? '[multiple]' 
            : info.row.original.paymentData.reference
        ) 
    },
    { accessorKey: 'totalAmount', header: 'Total Amount', cell: info => currencyFormat(info.getValue()), meta: { className: 'text-right' }},
    { accessorKey: 'currency', header: 'Currency', cell: info => (
        info.row.original.type === "Bulk" 
            ? info.row.original.paymentData.payments[0].amount.currency
            : info.row.original.paymentData.amount.currency
        ) 
    },
    { accessorKey: 'status', header: 'Status', cell: info => (
        <div className="flex items-center">
            {info.row.original.status === 'Approved' && <Check className="mr-2 w-5" />}
            {info.row.original.status === 'Rejected' && <X className="mr-2 w-5" />}
            <span>{info.row.original.status}</span>
        </div>
    )},
    { accessorKey: 'approvalProgress', header: 'Approval Progress', cell: info => (
        <CountProgress
            value={info.row.original.obtainedAuthorisations}
            total={info.row.original.requiredAuthorisations}
        />
    ), meta: { className: 'text-center' }},
    { accessorKey: 'action', header: '', cell: info => (
        <Button asChild className='yvr-button w-full font-semibold'>
            <Link to={`/payments/${info.row.original.paymentId}`}>
                View
            </Link>
        </Button>
    ), meta: { className: 'text-right' }},
];

export default function PaymentsTable({ payments, title, loading }) {
    const data = payments.reverse();
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    state: {
        globalFilter,
        columnVisibility,
        pagination,
    },
  })

  return (
    <>
    <Card>
        <CardHeader>
            <h3>{title}</h3>
        </CardHeader>
        <CardContent>
        <div className="w-full">
            {/* <div className="flex items-center py-4">
                <Input
                placeholder="Search payments..."
                value={globalFilter ?? ''}
                onChange={event => setGlobalFilter(event.target.value)}
                className="max-w-sm"
                />
            </div> */}
            <div className="">
                {loading ? <LoadingSpinner /> :
                    <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map(headerGroup => (
                        <TableRow key={headerGroup.id} className>
                            {headerGroup.headers.map(header => {
                            return (
                                <TableHead key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </TableHead>
                            )
                            })}
                        </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map(row => (
                            <TableRow
                            className={(row.original.status === 'Approved' || row.original.status === 'Rejected') && 'text-gray-400'}
                            key={row.id}
                            >
                            {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                                </TableCell>
                            ))}
                            </TableRow>
                        ))
                        ) : (
                        <TableRow>
                            <TableCell
                            colSpan={columns.length}
                            className="h-16 text-center"
                            >
                            No results.
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                    </Table>
                }
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="text-sm text-muted-foreground mr-6">
                    Page {pagination.pageIndex + 1} of {table.getFilteredRowModel().rows.length !== 0 ? Math.ceil(table.getFilteredRowModel().rows.length/10) : 1}
                </div>
                <div className="space-x-2">
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </Button>
                </div>
            </div>
            </div>
        </CardContent>
    </Card>
    </>
  )
}
