import React, {useEffect, useState} from 'react';
import '../index.css';
import UsersTable from "../components/users/UsersTable";
import CreateUserForm from "../components/users/CreateUserForm";
import UpdateAuthCount from "../components/users/UpdateAuthCount";
import { useAuth } from '../context/authContext';
import { getUsers, deleteUser, createUser, updateUser } from '../services/user.service';
import { useToast } from "../components/ui/use-toast"
import { getOrganisation } from '../services/organisation.service';

const UsersPage = () => {

    const [users, setUsers] = useState([]);
    const [authCount, setAuthCount] = useState(1);
    const [restrictSubmitterAuth, setRestrictSubmitterAuth] = useState(false);
    const [reload, setReload] = useState(false)
    const { user } = useAuth();
    const { toast } = useToast()

    useEffect(() => {
        const fetchData = async () => {
            const response = await getUsers(user.token, user.organisationId);
            if (response && response.status === 200) {
                setUsers(response.data.data);
            }
            const orgResponse = await getOrganisation(user.organisationId, user.token);
            if (orgResponse && orgResponse.status === 200 && orgResponse.data) {
                setAuthCount(orgResponse.data.data.requiredAuthorisations);
                setRestrictSubmitterAuth(orgResponse.data.data.restrictSubmitterAuth);
            }
        };

        fetchData();
    }, [user.token, user.organisationId, reload]);

    const handleCreateUser = async (newUser) => {
        if (newUser.maxAuthValue > 2147483647) {
            toast({
                title: "Error",
                description: "Max Auth Value is too large",
                variant: "destructive",
            });

            return;
        }

        const response = await createUser(user.token, user.organisationId, newUser);
        if (response && response.status === 201) {
            toast({
                title: "Success",
                description: "User created successfully",
                variant: "success",
            });
        } else {
            toast({
                title: "Error",
                description: "There was an error creating the user",
                variant: "destructive",
            });
        }
    }

    const handleDeleteUser = async (userId) => {
        // Check if the user is deleting themselves and they are the only user
        if (users.length === 1 && userId === user.id) {
            toast({
                title: "Error",
                description: "There must be at least one admin user in the organisation",
                variant: "destructive",
            });

            return false;
        }

        const response = await deleteUser(user.token, user.organisationId, userId);
        if (response && response.status === 200) {
            setUsers(users.filter(user => user.id !== userId));
            setReload(!reload);
            toast({
                title: "Success",
                description: "User deleted successfully",
                variant: "success",
            })

            return true;
        } else {
            toast({
                title: "Error",
                description: "There was an error deleting the user",
                variant: "destructive",
            })

            return false;
        }
    }

    const handleUpdateUser = async (selectedUser) => {
        const response = await updateUser(user.token, user.organisationId, selectedUser.id, selectedUser);
        if (response && response.status === 200) {

            setReload(!reload);

            toast({
                title: "Success",
                description: "User updated successfully",
                variant: "success",
            });
        } else {
            toast({
                title: "Error",
                description: "There was an error updating the user",
                variant: "destructive",
            });
        }
    }

    return (
        <>

            <div className="App">

                <div className='grid xl:grid-cols-3 xl:gap-8 gap-y-8 max-w-7xl mx-auto grid-cols-1'>

                    <div className='flex flex-col col-span-1 gap-6'>
                        <UpdateAuthCount 
                            users={users} 
                            authCount={authCount} 
                            setAuthCount={setAuthCount} 
                            restrictSubmitterAuth={restrictSubmitterAuth} 
                            setRestrictSubmitterAuth={setRestrictSubmitterAuth} 
                        />
                        <CreateUserForm createUser={handleCreateUser}/>
                    </div>
                    <div className='flex flex-col col-span-2'>
                        <UsersTable 
                            users={users} 
                            setUsers={setUsers} 
                            onDeleteUser={handleDeleteUser} 
                            onUpdateUser={handleUpdateUser} 
                        />
                    </div>

                </div>

            </div>

        </>
    );
};

export default UsersPage;